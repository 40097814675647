export abstract class HelpLinks {
    static readonly HELP_MENU = {
        // Dashboard
        DASH: "https://docs.zixi.com/zen-master/dashboard",
        // Live event
        NEW_LIVE_EVENT: "https://docs.zixi.com/zen-master/adding-a-live-event",
        LIVE_EVENTS: "https://docs.zixi.com/zen-master/live-events",
        VIEWING_EXISTING_EVENTS: "https://docs.zixi.com/zen-master/viewing-existing-events",
        VIEWING_EVENT_DETAILS: "https://docs.zixi.com/zen-master/viewing-event-details",
        MANUAL_CONTROLS: "https://docs.zixi.com/zen-master/live-event-manual-controls",
        // Roles
        CREATE_ROLE: "https://docs.zixi.com/zen-master/adding-roles",
        MANAGING_ROLES: "https://docs.zixi.com/zen-master/managing-roles",
        ROLES: "https://docs.zixi.com/zen-master/viewing-existing-roles",
        // SSOs
        CREATE_SINGLE_SIGN_ON: "https://docs.zixi.com/zen-master/adding-an-sso-account",
        SINGLE_SIGN_ON: "https://docs.zixi.com/zen-master/configuring-single-sign-on-account-settings",
        // User Groups
        CREATE_USER_GROUP: "https://docs.zixi.com/zen-master/adding-user-groups",
        USER_GROUPS: "https://docs.zixi.com/zen-master/viewing-existing-user-groups",
        // Users
        CREATE_USER: "https://docs.zixi.com/zen-master/adding-users",
        USERS: "https://docs.zixi.com/zen-master/viewing-existing-users",
        // Automation
        CREATE_TASK_SET: "https://docs.zixi.com/zen-master/adding-a-new-automation-task-set",
        CREATE_TASK: "https://docs.zixi.com/zen-master/adding-tasks-to-a-task-set",
        CREATE_SCHEDULE: "https://docs.zixi.com/zen-master/adding-a-schedule-to-a-task-set",
        AUTOMATION: "https://docs.zixi.com/zen-master/automation",
        VIEWING_AND_RUNNING_TASK_SETS: "https://docs.zixi.com/zen-master/viewing-and-running-task-sets",
        // Channels
        NEW_ADAPTIVE_CHANNEL: "https://docs.zixi.com/zen-master/adding-adaptive-channels",
        NEW_TRANSCODED_CHANNEL: "https://docs.zixi.com/zen-master/adding-transcoded-channels",
        NEW_PASSTHROUGH_CHANNEL: "https://docs.zixi.com/zen-master/adding-pass-through-channels",
        NEW_MEDIACONNECT_CHANNEL: "https://docs.zixi.com/zen-master/adding-mediaconnect-flow-channels",
        NEW_MEDIALIVE_CHANNEL: "https://docs.zixi.com/zen-master/adding-a-aws-medialive-channel",
        NEW_FAILOVER_CHANNEL: "https://docs.zixi.com/zen-master/adding-failover-channels",
        CHANNELS: "https://docs.zixi.com/zen-master/viewing-existing-channels",
        // Clusters
        NEW_CLUSTER: "https://docs.zixi.com/zen-master/creating-a-new-broadcaster-cluster",
        NEW_BROADCASTER: "https://docs.zixi.com/zen-master/adding-broadcasters-to-a-cluster",
        BROADCASTER_CLUSTER_CONFIG:
            "https://docs.zixi.com/zen-master/broadcaster-cluster-configuration-parameters-table-for-manual-and-managed-clusters",
        CLUSTERS: "https://docs.zixi.com/zen-master/viewing-existing-clusters",
        BROADCASTER: "https://docs.zixi.com/zen-master/viewing-broadcaster-details",
        INSIGHTS: "https://docs.zixi.com/zen-master/viewing-the-graphs-for-multiple-resources",
        // API Keys
        API_KEYS: "https://docs.zixi.com/zen-master/configuring-api-keys",
        // AWS Accounts
        AWS_ACCOUNTS: "https://docs.zixi.com/zen-master/configuring-aws-account-credentials",
        // Azure Accounts
        AZURE_ACCOUNTS: "https://docs.zixi.com/zen-master/configuring-microsoft-azure-account-credentials",
        // Events Management
        ADDING_NEW_EVENTS_PROFILE: "https://docs.zixi.com/zen-master/adding-a-new-events-profile",
        CONFIG_EVENTS_PROFILES: "https://docs.zixi.com/zen-master/configuring-events-profiles",
        EDITING_EVENTS_PROFILE_RULES: "https://docs.zixi.com/zen-master/editing-events-profile-rules",
        VIEWING_EXISTING_EVENTS_PROFILES: "https://docs.zixi.com/zen-master/viewing-existing-events-profiles",
        // GCP Accounts
        GCP_ACCOUNTS: "https://docs.zixi.com/zen-master/configuring-google-gcp-account-credentials",
        // Linode Accounts
        LINODE_ACCOUNTS: "https://docs.zixi.com/zen-master/configuring-microsoft-azure-account-credentials",
        // Notifications
        FLAPPING_DETECTION: "https://docs.zixi.com/zen-master/configuring-flapping-detection",
        KAFKA_SERVERS: "https://docs.zixi.com/zen-master/configuring-kafka-servers",
        // SSH Keys
        SSH_KEYS: "https://docs.zixi.com/zen-master/configuring-ssh-keys",
        // Tags
        CREATE_ACCESS_TAG: "https://docs.zixi.com/zen-master/creating-a-tag",
        TAGS: "https://docs.zixi.com/zen-master/configuring-tags",
        // Events
        EVENTS: "https://docs.zixi.com/zen-master/events",
        // Grids
        GRIDS: "https://docs.zixi.com/zen-master/grids",
        CREATING_A_GRID: "https://docs.zixi.com/zen-master/creating-a-grid",
        VIEWING_STATUS_GRID_DISPLAY: "https://docs.zixi.com/zen-master/viewing-status-grid-display",
        VIEWING_THUMBNAIL_GRID_DISPLAY: "https://docs.zixi.com/zen-master/viewing-thumbnail-grid-display",
        CREATING_A_GRID_GROUP: "https://docs.zixi.com/zen-master/creating-a-grid-group",
        VIEWING_GRID_GROUPS: "https://docs.zixi.com/zen-master/viewing-grid-groups",
        // Incidents
        VIEWING_AN_INCIDENT: "https://docs.zixi.com/zen-master/viewing-existing-incidents",
        INCIDENTS: "https://docs.zixi.com/zen-master/incidents",
        CREATING_AN_INCIDENT: "https://docs.zixi.com/zen-master/creating-an-incident-manually",
        // Maps
        CREATE_MAP: "https://docs.zixi.com/zen-master/creating-a-map",
        VIEWING_MAP: "https://docs.zixi.com/zen-master/viewing-map-display",
        MAPS: "https://docs.zixi.com/zen-master/maps",
        // PID Mappings
        NEW_PID_MAPPING: "https://docs.zixi.com/zen-master/adding-pid-mapping-profiles",
        PID_MAPPING: "https://docs.zixi.com/zen-master/viewing-existing-pid-mapping-profiles",
        // Remote Access
        CREATE_REMOTE_ACCESS: "https://docs.zixi.com/zen-master/adding-a-zixi-remote-access-profile",
        REMOTE_ACCESS: "https://docs.zixi.com/zen-master/viewing-existing-remote-access-profiles",
        // Reports
        NEW_REPORT: "https://docs.zixi.com/zen-master/adding-new-reports",
        REPORTS: "https://docs.zixi.com/zen-master/reports",
        GENERATING_REPORTS: "https://docs.zixi.com/zen-master/generating-reports",
        VIEWING_AND_DOWNLOADING_REPORTS: "https://docs.zixi.com/zen-master/viewing-and-downloading-reports",
        UNDERSTANDING_REPORT_CONTENT: "https://docs.zixi.com/zen-master/understanding-report-content",
        // SCTE
        SCTE35: "https://docs.zixi.com/zen-master/scte-35",
        ACTIVATING_SCTE35_LOGGING: "https://docs.zixi.com/zen-master/activating-scte-35-logging",
        // Sources
        NEW_SOURCE: "https://docs.zixi.com/zen-master/sources",
        NEW_ZEC_SOURCE: "https://docs.zixi.com/zen-master/adding-a-zixi-source-zec",
        NEW_FEEDER_SOURCE: "https://docs.zixi.com/zen-master/adding-a-zixi-source-feeder",
        NEW_BROADCASTER_SOURCE: "https://docs.zixi.com/zen-master/adding-a-zixi-source-broadcaster",
        NEW_MEDIACONNECT_SOURCE: "https://docs.zixi.com/zen-master/adding-an-aws-mediaconnect-source",
        NEW_OTHER_SOURCE: "https://docs.zixi.com/zen-master/adding-a-zixi-source-other-enabled-device",
        NEW_MONITOR_SOURCE: "https://docs.zixi.com/zen-master/adding-a-source-monitor-only",
        NEW_FILE_SOURCE: "https://docs.zixi.com/zen-master/adding-a-source-file",
        NEW_TRANSCODED_SOURCE: "https://docs.zixi.com/zen-master/adding-a-source-transcoded",
        NEW_NDI_SOURCE: "https://docs.zixi.com/zen-master/adding-a-source-ndi",
        NEW_PIDMAP_SOURCE: "https://docs.zixi.com/zen-master/adding-a-source-pid-mapping",
        NEW_FAILOVER_SOURCE: "https://docs.zixi.com/zen-master/adding-a-source-failover",
        NEW_INTERCLUSTER_SOURCE: "https://docs.zixi.com/zen-master/adding-a-source-inter-cluster",
        NEW_TO_MEDIACONNECT_SOURCE: "https://docs.zixi.com/zen-master/adding-a-zixi-protocol-mediaconnect-source",
        NEW_UDPRTP_SOURCE: "https://docs.zixi.com/zen-master/adding-a-source-udprtp",
        NEW_SRT_SOURCE: "https://docs.zixi.com/zen-master/adding-a-source-srt",
        NEW_RIST_SOURCE: "https://docs.zixi.com/zen-master/adding-a-source-rist",
        NEW_RTMP_SOURCE: "https://docs.zixi.com/zen-master/adding-a-source-rtmp",
        NEW_HLS_PULL_SOURCE: "https://docs.zixi.com/zen-master/adding-a-source-hls",
        NEW_DEMUX_SOURCE: "https://docs.zixi.com/zen-master/adding-a-source-demux",
        NEW_MULTIVIEW_SOURCE: "https://docs.zixi.com/zen-master/adding-a-source-multiviewer",
        NEW_MULTIPLEX_SOURCE: "https://docs.zixi.com/zen-master/adding-a-source-multiplex",
        SOURCES: "https://docs.zixi.com/zen-master/viewing-existing-sources",
        // Targets
        NEW_HTTP_TARGET: "https://docs.zixi.com/zen-master/adding-http-targets",
        NEW_ZIXI_PULL_TARGET: "https://docs.zixi.com/zen-master/adding-zixi-pull-targets",
        NEW_ZIXI_PUSH_TARGET: "https://docs.zixi.com/zen-master/adding-zixi-push-targets",
        NEW_MEDIACONNECT_TARGET: "https://docs.zixi.com/zen-master/adding-zixi-aws-mediaconnect-targets",
        NEW_RIST_TARGET: "https://docs.zixi.com/zen-master/adding-rist-targets",
        NEW_UDPRTP_TARGET: "https://docs.zixi.com/zen-master/adding-udprtp-targets",
        NEW_RTMP_TARGET: "https://docs.zixi.com/zen-master/adding-rtmp-targets",
        NEW_SRT_TARGET: "https://docs.zixi.com/zen-master/adding-srt-targets",
        NEW_NDI_TARGET: "https://docs.zixi.com/zen-master/adding-ndi-targets",
        NEW_CDI_TARGET: "https://docs.zixi.com/zen-master/adding-cdi-targets",
        NEW_JPEGXS_TARGET: "https://docs.zixi.com/zen-master/adding-st-2110-jpeg-xs-targets",
        NEW_MEDIALIVE_HTTP_TARGET: "https://docs.zixi.com/zen-master/adding-medialive-http-targets",
        NEW_ENTITLEMENT_TARGET: "https://docs.zixi.com/zen-master/adding-entitlement-targets",
        TARGETS: "https://docs.zixi.com/zen-master/viewing-existing-targets",
        // Transcoding Profiles
        NEW_TRANSCODING_PROFILE: "https://docs.zixi.com/zen-master/adding-transcoding-profiles",
        TRANSCODING: "https://docs.zixi.com/zen-master/viewing-existing-transcoding-profiles",
        // ZECs
        NEW_ZEC: "https://docs.zixi.com/zen-master/adding-a-new-zec",
        NEW_FEEDER: "https://docs.zixi.com/zen-master/mftO-adding-a-new-zixi-feeder",
        NEW_RECEIVER: "https://docs.zixi.com/zen-master/adding-a-new-zixi-receiver",
        ZIXI_EDGE_DEVICES: "https://docs.zixi.com/zen-master/zixi-edge-devices",
        EDITING_ACCESS_TAGS_AND_CONFIG_PROFILES_OF_MULTIPLE_ZECS:
            "https://docs.zixi.com/zen-master/editing-the-tags-and-configuration-profiles-of-multiple-zixi-edge-devices",
        VIEWING_EXISTING_ZECS: "https://docs.zixi.com/zen-master/viewing-existing-zec",
        VIEWING_EXISTING_RECEIVERS: "https://docs.zixi.com/zen-master/viewing-existing-zixi-receivers",
        VIEWING_EXISTING_FEEDERS: "https://docs.zixi.com/zen-master/viewing-existing-zixi-feeders"
    };
}
